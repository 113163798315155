<template>
  <div class="background-login" id="bg-login">
    <div>
      <login />
    </div>
  </div>
</template>

<script>
import login from "@/components/login/login.vue";
export default {
  components: { login },
  mounted() {
    // this.moveBackgroundImage();
  },
  methods: {},
};
</script>
<style scoped>
.background-login {
  background-color: #1daf80 !important;
  /* background-image: url("../assets/images/BG_Pettree.png"); */
  position: fixed;
  height: 100%;
  width: 100%;
  background-size: cover;
}
</style>
