<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    persistent
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        depressed
        color="success"
        class="mb-3"
        v-bind="attrs"
        v-on="on"
        v-if="allowCadastrarBtn"
      >
        Cadastrar Cliente
      </v-btn>
    </template>
    <v-card>
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Cadastrar Cliente</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <cadastrar-tutor
        v-if="dialog"
        @close="dialog = false"
        @response="atualizarListaPacientes"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import CadastrarTutor from "./form_cadastrar.vue";

export default {
  components: {
    CadastrarTutor,
  },
  data() {
    return {
      dialog: false,
      allowCadastrarBtn: true,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.dialogCadastro = false;
      this.dialogObs = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.dialogCadastro = false;
      if (this.editedIndex > -1) {
        Object.assign(this.pacientes[this.editedIndex], this.editedItem);
      } else {
        this.pacientes.push(this.editedItem);
      }
      this.paciente_detalhe = false;
      this.options.ordenacao = false;
      this.options.desc = false;
      this.close();
    },
  },
};
</script>
