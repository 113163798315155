<template>
  <div class="info-container d-flex justify-content-between w-50 ml-3">
    <div class="text-left">
      <v-card-text>
        <div class="mb-3">
          <!--   <div class="mb-3" v-if="perfil == 2">
            <v-dialog v-model="dialogRequisitarExame" persistent fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  title="REQUISITAR EXAMES"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-test-tube</v-icon>
                </v-btn>
              </template>
              <RequisitarExamesPets
                v-if="dialogRequisitarExame"
                @close="() => (dialogRequisitarExame = false)"
                :petId="infoPaciente.PetCodigo"
                :tutorId="infoPaciente.TutorCodigo"
              />
            </v-dialog>

            <v-dialog
              persistent
              fullscreen
              v-model="dialogRequisitarInternacao"
              class="icone-requerimento-modal"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="carregarPet()"
                  small
                  title="REQUISITAR INTERNAÇÃO"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-hospital-building</v-icon>
                </v-btn>
              </template>
              <requisitar-internacao
                v-if="dialogRequisitarInternacao"
                :petId="petRequisicao"
                :tutorId="tutorRequisicao"
                :consultaId="0"
                @close="dialogRequisitarInternacao = false"
              ></requisitar-internacao>
            </v-dialog>

            <v-dialog persistent fullscreen v-model="dialogRequisitarMedicacao">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  title="REQUISITAR MEDICAÇÃO"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-pill</v-icon>
                </v-btn>
              </template>
              <requisitar-medicacao
                v-if="dialogRequisitarMedicacao"
                :petId="infoPaciente.PetCodigo"
                :consultaId="0"
                @close="dialogRequisitarMedicacao = false"
              ></requisitar-medicacao>
            </v-dialog>

            <v-dialog persistent fullscreen v-model="dialogRequisitarCirurgia">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="carregarPet()"
                  small
                  title="REQUISITAR CIRURGIA"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-box-cutter</v-icon>
                </v-btn>
              </template>
              <requisitar-cirurgia
                v-if="dialogRequisitarCirurgia"
                :petId="petRequisicao"
                :tutorId="tutorRequisicao"
                :consultaId="0"
                @close="dialogRequisitarCirurgia = false"
              ></requisitar-cirurgia>
            </v-dialog>

            <v-dialog persistent fullscreen v-model="dialogRequisitarConsulta">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="carregarPet()"
                  small
                  title="REQUISITAR CONSULTA"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-stethoscope</v-icon>
                </v-btn>
              </template>
              <requisitar-consulta
                v-if="dialogRequisitarConsulta"
                :petId="petRequisicao"
                :tutorId="tutorRequisicao"
                @close="dialogRequisitarConsulta = false"
              ></requisitar-consulta>
            </v-dialog>
          </div> -->
          <v-dialog
            v-model="dialogRequisicao"
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="btn-style-green" v-bind="attrs" v-on="on">
                Requisições
              </v-btn>
            </template>
            <requisicao-modal
              v-if="dialogRequisicao"
              @close="dialogRequisicao = false"
              :petId="infoPaciente.PetCodigo"
              :tutorId="infoPaciente.TutorCodigo"
            >
            </requisicao-modal>
          </v-dialog>
          <v-btn class="btn-style-green" @click="handleDialogComandas">
            Comandas
          </v-btn>
          <template v-if="dialogComandas">
            <v-dialog
              v-model="dialogComandas"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar dark style="background-color: #1daf80; color: #fff">
                  <v-btn icon dark @click="dialogComandas = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Comandas </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>

                <modal-comandas :tutor="tutor" :petId="petIdComanda" />
              </v-card>
            </v-dialog>
          </template>

          <v-btn @click="listProntuarios" class="btn-style-green">
            Prontuários
          </v-btn>

          <v-btn @click="listUpload" class="btn-style-green">
            Upload de Exames
          </v-btn>

          <v-btn @click="gerarReceita" class="btn-style-green">
            Receita
          </v-btn>

          <v-dialog
            v-model="dialogReceita"
            v-if="dialogReceita"
            hide-overlay
            persistent
            width="60%"
          >
            <receita @close="close()" @gerarPDF="gerarPDF($event)" />
          </v-dialog>

          <v-dialog
            v-model="dialogObs"
            hide-overlay
            scrollable
            transition="dialog-bottom-transition"
          >
            <obs-assistente
              :paciente="paciente"
              :key="render"
              @close="save($event)"
            />
          </v-dialog>

          <v-dialog
            maxWidth="800px"
            v-model="dialogAddAnaminese"
            :persistent="anamneseCriada"
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- v-if="perfilId != 1" -->
              <v-btn
                v-if="true"
                class="btn-style-green"
                color="#a4005c"
                v-bind="attrs"
                v-on="on"
              >
                <span>Anamnese Adicional</span>
              </v-btn>
            </template>
            <v-card>
              <v-toolbar style="background-color: #1daf80; color: #fff">
                <v-btn
                  icon
                  dark
                  @click="dialogAddAnaminese = false"
                  v-if="!anamneseCriada"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span class="headline">Anamnese Adicional</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items> </v-toolbar-items>
              </v-toolbar>
              <modal-nova-anaminese
                v-if="dialogAddAnaminese"
                :paciente="infoPaciente"
                @close="dialogAddAnaminese = false"
                @setAnamneseCriada="setAnamneseCriada"
              />
            </v-card>
          </v-dialog>
          <!-- <v-btn
            @click="dialogSelectRequisicao = true"
            v-if="perfil == 3"
            class="btn-style-green"
          >
            Requisições
          </v-btn> -->
        </div>
        <span>
          <strong>Cliente: </strong>
          {{ infoPaciente.NomeTutor || infoPaciente.UsuarioSenha }} </span
        ><br />
        <span>
          <strong>Paciente: </strong>
          {{ infoPaciente.PetCodigo + " - " + infoPaciente.NomePet }} </span
        ><br />
        <!-- <v-dialog v-model="dialogObservacoes" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              depressed
              color="error"
              v-bind="attrs"
              v-on="on"
              class="mt-3"
              >Observações
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5 bg-danger text-white lighten-2">
              Observações
            </v-card-title>

            <h5 class="text-justify pt-5 px-5">
              {{ infoPaciente.ObservacaoExtra }}
            </h5>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogObservacoes = false">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card> </v-dialog
        ><br /> -->
        <!-- <span>
          <strong>Observações: </strong>
          {{ infoPaciente.Observacao }} </span
        > -->
        <span class="text-justify font-weight-bold">
          Observações:
          <b class="red--text font-weight-regular">{{
            infoPaciente.ObservacaoExtra
          }}</b> </span
        ><br />

        <!-- <v-dialog
          v-model="dialogSelectProntuarios"
          v-if="dialogSelectProntuarios"
          max-width="500px"
        >
          <v-card>
            <v-card-title>Visualizar Prontuarios</v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="selectedTutorProntuarios"
                :loading="loadingSearch"
                :items="tutores"
                :search-input.sync="searchTutorProntuarios"
                hide-no-data
                hide-selected
                label="Selecione um tutor"
                item-text="nome"
                return-object
              ></v-autocomplete>
            </v-card-text>

            <v-card-text>
              <v-select
                :items="pets"
                v-model="selectedPetProntuarios"
                label="Selecione um pet"
                item-text="nome"
                return-object
              ></v-select>
            </v-card-text>

            <v-card-actions>
              <v-btn color="gray" text @click="dialogSelectProntuarios = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                :disabled="!selectedPetProntuarios"
                color="primary"
                @click="listProntuarios"
              >
                Buscar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <v-dialog
          v-model="dialogProntuarios"
          v-if="dialogProntuarios"
          fullscreen
          persistent
        >
          <prontuarios
            @close="() => (dialogProntuarios = false)"
            :pacienteInfo="pacienteInfo"
            :consultas="prontuarios"
          />
        </v-dialog>
        <v-dialog
          v-model="dialogUploadExames"
          v-if="dialogUploadExames"
          fullscreen
          hide-overlay
          persistent
          transition="dialog-bottom-transition"
        >
          <UploadExames
            :paciente="paciente"
            @close="dialogUploadExames = false"
          />
        </v-dialog>
        <v-dialog v-model="dialogErrorProtuarios" width="400">
          <v-card>
            <v-card-title class="text-h5 bg-danger text-white lighten-2">
              Error
            </v-card-title>

            <h4 class="text-center pt-5 px-5">
              Dados não encontrados
            </h4>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogErrorProtuarios = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogSelectRequisicao"
          v-if="dialogSelectRequisicao"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="primary white--text"
              >Requisitar Serviço</v-card-title
            >
            <v-card-text class="my-4">
              <v-select
                :items="requisicoes"
                v-model="selectedRequisicao"
                label="Selecione o que deseja requisitar"
              ></v-select>
            </v-card-text>
            <v-card-actions class="d-flex border justify-end">
              <v-btn color="gray" text @click="dialogSelectRequisicao = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                :disabled="!selectedRequisicao"
                color="primary"
                @click="requisitar"
              >
                Requisitar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRequisitarExame" fullscreen persistent>
          <RequisitarExamesPets
            v-if="dialogRequisitarExame"
            @close="() => (dialogRequisitarExame = false)"
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-dialog>
        <v-dialog v-model="dialogRequisitarMedicacao" fullscreen persistent>
          <RequisitarMedicacao
            v-if="dialogRequisitarMedicacao"
            @close="() => (dialogRequisitarMedicacao = false)"
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-dialog>
      </v-card-text>
    </div>

    <div ref="pdfReceita" id="pdfReceita" v-show="false">
      <div id="header" class="m-0 p-0 d-flex">
        <img
          src="@/assets/images/logo-login.png"
          style="margin: auto; width: 30px;"
          alt="Logo Medtree"
        />
      </div>
      <div id="main">
        <div
          v-if="htmlToPdf == 'receita'"
          class="carbon zeroEspaco color-black"
        >
          <div class="body">
            <div class="d-flex justify-content-between align-items-center">
              <p>{{ informacoes_unidade.nome }}</p>
              <div class="text-right">
                <p>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} -
                  {{ informacoes_unidade.cidade }}
                </p>
                <p>CEP: {{ informacoes_unidade.cep }}</p>
                <p>Tel.: {{ informacoes_unidade.telefone }}</p>
                <p>CNPJ: {{ informacoes_unidade.cnpj }}</p>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between align-items-stretch gap">
              <div class="border p-1">
                <b>Identificação do Emitente</b><br />
                <br /><b>Nome: </b> <span>{{ usuario.nome }}</span> <br /><b
                  >CRM: </b
                >{{ crmv }} <span></span> <br /><b>Endereço: </b>
                <span>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} </span
                ><br />
                <span>
                  {{ informacoes_unidade.cidade }} -
                  {{ informacoes_unidade.cep }}
                </span>
              </div>
              <div class="border p-1">
                <b>Paciente</b><br />
                <br />
                <b>Nome: </b> <span>{{ petReceita.nome_pet }}</span> <br />
                <b>Idade: </b> <span>{{ petReceita.idade }}</span> <br />
                <!-- <b>Espécie: </b> <span>{{ petReceita.nome_especie }}</span> -->
                <!-- <br /> -->
                <!-- <b>Raça: </b> <span>{{ petReceita.nome_raca }}</span> <br /> -->
                <b>Sexo: </b> <span>{{ petReceita.sexo }}</span> <br />
                <!-- <b>Peso: </b> <span>{{ petReceita.peso }}</span> -->
              </div>
              <div class="border p-1">
                <b>Cliente</b><br />
                <br />
                <b>Nome: </b> <span>{{ tutorReceita.nome }}</span> <br />
                <b>CPF: </b> <span>{{ tutorReceita.cpf }}</span> <br />
                <b>Endereço: </b>
                <span>
                  {{ tutorReceita.logradouro }},
                  {{ tutorReceita.numero }} </span
                ><br />
                <span>
                  {{ tutorReceita.cidade }} - {{ tutorReceita.cep }}
                </span>
              </div>
            </div>
            <div id="intensReceitaTeste" class="ml-1" style="width: 94%;"></div>
          </div>
          <div>
            <br /><br />
            <hr />
            <div
              class="
                d-flex
                justify-content-between
                align-items-stretch
                gap
              "
            >
              <div>
                <p>
                  {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                  }}<span class="space">_</span>de<span class="space">_</span
                  >{{ gerarData().mes }}<span class="space">_</span
                  >{{ gerarData().ano }}.
                </p>
                <p>
                  <b>{{ informacoes_unidade.nome }}</b>
                </p>
                <p>CRM: {{ crmv }}</p>
                <br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
              <div>
                <p><b>Identificação do Comprador</b></p>
                <p>Nome:</p>
                <p>RG:</p>
                <p>Endereço:</p>
                <p>Cidade e UF:</p>
                <p>Telefone:</p>
              </div>
              <div>
                <p><b>Identificação do Fornecedor</b></p>
                <p>Data:</p>
                <br /><br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadExames from "@/components/prontuarios/uploadExames.vue";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import PetsService from "@/services/pets_service.js";
import PacienteService from "@/services/paciente_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import RequisitarExamesPets from "@/components/requisitar_exames/requisitar_exame.vue";
import RequisitarMedicacao from "@/components/recepcao/infoChamada/buttons/requisitar-medicacao-pet.vue";
//import RequisitarConsulta from "@/components/recepcao/infoChamada/buttons/marcar-consulta/marcarConsulta.vue";
//import RequisitarInternacao from "@/components/recepcao/infoChamada/buttons/requisitar-internacao";
// RequisitarCirurgia from "@/components/requisitar_cirurgia";
import RequisicaoModal from "@/components/recepcao_medico/requisicaoModal/index.vue";
import ModalComandas from "@/components/pacientes/modal_comandas_teste.vue";
import ModalNovaAnaminese from "@/components/recepcao_medico/infoChamada/nova_anaminese";
import Receita from "@/components/documentos/receita.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  mixins: [Mixin],
  components: {
    UploadExames,
    Prontuarios,
    RequisitarExamesPets,
    RequisitarMedicacao,
    //RequisitarConsulta,
    //RequisitarInternacao,
    //RequisitarCirurgia,
    RequisicaoModal,
    ModalComandas,
    ModalNovaAnaminese,
    Receita,
  },
  data() {
    return {
      anamneseCriada: false,
      rederComp: 0,
      dialogErrorProtuarios: false,
      dialogObservacoes: false,
      infoPaciente: {},
      loadingSearch: false,
      tutores: [],
      pets: [],
      prontuarios: [],
      dialogSelectProntuarios: false,
      dialogProntuarios: false,
      selectedTutorProntuarios: "",
      selectedPetProntuarios: "",
      searchTutorProntuarios: "",
      petService: PetsService.build(),
      pacienteService: new PacienteService(),
      requisicoes: ["Exame", "Medicação"],
      dialogSelectRequisicao: false,
      dialogRequisitarExame: false,
      dialogRequisitarMedicacao: false,
      dialogComandas: false,
      selectedRequisicao: "",
      perfil: "",
      dialogRequisitarCirurgia: false,
      dialogRequisitarConsulta: false,
      dialogRequisitarInternacao: false,
      dialogRequisicao: false,
      tutor: {},
      petIdComanda: null,
      tutorRequisicao: {},
      petRequisicao: {},
      dialogAddAnaminese: false,
      perfilId: JSON.parse(sessionStorage.vuex).perfil.id,
      dialogReceita: false,
      convenioPublico: true,
      htmlToPdf: "",
      petReceita: {},
      tutorReceita: {},
      informacoes_unidade: {},
      pacienteInfo: {},
      dialogUploadExames: false,
      paciente: {},
    };
  },
  watch: {
    searchTutorProntuarios(nome) {
      if (!nome) {
        return;
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    selectedTutorProntuarios() {
      this.pets = "";
      this.pets = this.selectedTutorProntuarios.pets;
    },
  },
  beforeMount() {
    this.informacoes_unidade = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade;
    this.usuario = JSON.parse(sessionStorage.getItem("vuex")).usuario.dados;
    this.crmv = JSON.parse(sessionStorage.getItem("vuex")).perfil.documento;
    console.log(this.informacoes_unidade);
    this.infoPaciente = JSON.parse(localStorage.getItem("infoPaciente"));
    console.log(this.infoPaciente);
    this.perfil = JSON.parse(sessionStorage.vuex).perfil.id;
  },
  methods: {
    setAnamneseCriada() {
      this.anamneseCriada = true;
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      let dataFormatada = {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
      return dataFormatada;
    },
    forceRender() {
      this.rederComp += 1;
    },
    listUpload() {
      this.paciente = {
        id_paciente: this.infoPaciente.TutorCodigo,
        id_pet: this.infoPaciente.PetCodigo,
      };
      this.dialogUploadExames = true;
    },
    carregarPet() {
      this.tutorRequisicao = {
        nome: this.infoPaciente.NomeTutor,
        id_cliente: this.infoPaciente.TutorCodigo,
      };
      this.petRequisicao = {
        nome: this.infoPaciente.NomePet,
        id_pet: this.infoPaciente.PetCodigo,
      };
    },
    async handleDialogComandas() {
      if (this.infoPaciente.TutorCodigo) {
        this.petIdComanda = this.infoPaciente.PetCodigo;
        const pacienteService = new PacienteService();
        this.tutor = await pacienteService
          .getPacienteByClienteId(this.infoPaciente.TutorCodigo)
          .then((resp) => resp.data);
        this.dialogComandas = true;
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Paciente não identificado.",
          color: "error",
        });
        return;
      }
    },
    async listProntuarios() {
      await this.petService
        // .getConsultas(this.infoPaciente.PetCodigo)
        .getConsultasbyUnidade(
          this.infoPaciente.PetCodigo,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            // this.$_ACTIONS_showSnackbarMessage({
            //   message: data.message,
            //   color: "error",
            // });
            this.dialogErrorProtuarios = true;
            return;
          }
          console.log(data);
          this.prontuarios = data.data.consultas;
          this.pacienteInfo = {
            nome: this.infoPaciente.NomeTutor,
            nome_pet: this.infoPaciente.NomePet,
            id_pet: this.infoPaciente.PetCodigo,
            id_cliente: this.infoPaciente.TutorCodigo,
          };
          this.dialogProntuarios = true;
          this.dialogSelectProntuarios = false;
        });
    },
    requisitar() {
      this.dialogSelectRequisicao = false;

      if (this.selectedRequisicao == "Exame") {
        this.dialogRequisitarExame = true;
      } else if (this.selectedRequisicao == "Medicação") {
        this.dialogRequisitarMedicacao = true;
      }
    },
    async gerarReceita() {
      this.htmlToPdf = "receita";
      await this.petService
        .getPetById(this.infoPaciente.PetCodigo)
        .then(async (response) => {
          console.log(response);
          this.petReceita = {
            ...response,
            idade: this.calcularIdade(response.data_nascimento),
          };
        });

      await this.pacienteService
        .getPacienteByClienteId(this.infoPaciente.TutorCodigo)
        .then((resp) => {
          console.log(resp);
          this.tutorReceita = resp.data;
        });

      this.dialogReceita = true;
    },
    calcularIdade(dataNascimento) {
      if (!dataNascimento) {
        return 0;
      }
      const dataAtual = new Date();
      const dataNascimentoPet = new Date(dataNascimento);

      let anos = dataAtual.getFullYear() - dataNascimentoPet.getFullYear();
      let meses =
        anos * 12 + (dataAtual.getMonth() - dataNascimentoPet.getMonth());

      if (meses < 12) {
        return `${meses} meses`;
      } else {
        return `${Math.floor(meses / 12)} anos e ${meses % 12} meses`;
      }
    },
    close() {
      this.dialogReceita = false;
      this.forceRender();
    },
    gerarPDF(info) {
      this.convenioPublico = info.convenio == "publico" ? true : false;
      switch (info.doc) {
        case "receita":
          document.getElementById("intensReceitaTeste").innerHTML =
            info.receita;
          this.receitas = document.getElementById(
            "intensReceitaTeste"
          ).children;
          this.receitas.forEach((item) => {
            console.log(item.innerText);
            item.style.cssText =
              "margin: 3px; font-size: 4px; width: 97%; font-family: Times, Times New Roman, serif;";
          });

          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.pdfReceita.innerHTML, "Receita", "padrao");
          });
          break;
        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      console.log(this.htmlToPdf);
      console.log(content);
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        var doc = new jsPDF("p", "mm", "a4");
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else {
        var docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },
    // dialogSelectPront() {
    //   this.selectedPetProntuarios = "";
    //   this.selectedTutorProntuarios = "";
    //   this.searchTutorProntuarios = "";
    //   this.dialogSelectProntuarios = true;
    // },
  },
};
</script>

<style scoped>
#pdfReceita {
  text-align: center;
}

p {
  letter-spacing: 0.3px;
}

strong {
  letter-spacing: 0.5px !important;
}

#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header img {
  width: 1rem;
}
.header-receita {
  margin: 0 !important;
  padding: 0 !important;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.carbon {
  width: 100vw !important;
  font-size: 3px;
}

.carbon .body {
  min-height: 30vh !important;
}
.carbon .rodape {
  min-height: 70vh !important;
  position: static !important;
  bottom: 0 !important;
}

.carbon p,
.carbon hr {
  margin: 0 !important;
  padding: 0 !important;
}
.gap {
  gap: 1px;
}
.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.zeroEspaco {
  padding: 0 !important;
  margin: 0 !important;
}
.marg-2 {
  margin-left: 0.7rem !important;
  margin-right: 0.7rem !important;
}
.indent {
  text-indent: 5px;
}
.termo {
  font-size: 3.5px;
  color: black;
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
}
.color-black {
  color: black;
}
.rodape {
  position: fixed;
  bottom: 0;
}
.termo .small {
  line-height: 4px !important;
  letter-spacing: 0.2px !important;
  font-size: 3.2px;
}
.listaIntensReceita {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  color: brown !important;
}
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}

.v-application .mb-3 {
  margin-bottom: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 1080px) {
  .info-container {
    width: 60vw !important;
    margin: 0 !important;
  }
}
</style>
