<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">{{ titulo }}</h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="pets"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG DE CADASTRO -->
          <v-dialog
            persistent
            fullscreen
            v-model="dialogCadastrar"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                class="mb-2m= btn-style-green"
                v-bind="attrs"
                v-on="on"
              >
                Novo Paciente
              </v-btn>
            </template>
            <modal-cadastro
              v-if="dialogCadastrar"
              @close="closeDialogCadastrar"
              @response="atualizarPetPosCadastro"
              :nomeClienteCadastrado="tutor.nome"
              :idClienteCadastrado="tutor.id_cliente"
            />
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <!-- DIALOG DE EXCLUSÃO-->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline" style="word-break: normal"
                >Tem certeza de que deseja excluir este Paciente?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3E682A" text @click="closeDelete"
                  >Cancelar</v-btn
                >
                <v-btn
                  :loading="loadingDelete"
                  class="btn-danger"
                  text
                  style="color: #fff !important"
                  @click="deleteItemConfirm"
                  >Deletar</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar pelo nome do cliente"
            single-line
            hide-details
          />
          <!-- DIALOG DE EDIÇÃO -->
          <v-dialog
            fullscreen
            hide-overlay
            max-width="500px"
            v-if="dialogEdicao"
            v-model="dialogEdicao"
          >
            <modal-edicao
              v-if="dialogEdicao"
              :petId="editedPetId"
              :visualizar="visualizar"
              @close="closeDialogEdited"
              @response="atualizarPetPosEdicao($event)"
            />
          </v-dialog>

          <v-dialog
            v-model="dialogArquivo"
            fullscreen
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
          >
            <arquivo
              :paciente="petArquivo"
              @close="() => (dialogArquivo = false)"
            />
          </v-dialog>

          <v-dialog
            fullscreen
            hide-overlay
            max-width="500px"
            v-model="dialogAgendarPet"
          >
            <modal-pet-agendamento
              :pet="petAgendamento"
              @close="closeDialogAgendarPet"
              @response="atualizarPetPosEdicao($event)"
            />
          </v-dialog>

          <!-- DIALOG DE REQUISITAR CONSULTA -->
          <v-dialog
            v-model="dialogRequisitarConsulta"
            v-if="dialogRequisitarConsulta"
            fullscreen
            persistent
          >
            <RequisitarConsulta
              @close="() => (dialogRequisitarConsulta = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>

          <!-- DIALOG DE REQUISITAR EXAMES -->
          <v-dialog
            v-model="dialogRequisitarExames"
            v-if="dialogRequisitarExames"
            fullscreen
            persistent
          >
            <RequisitarExamesPets
              v-if="dialogRequisitarExames"
              @close="() => (dialogRequisitarExames = false)"
              :petId="petId"
              :tutorId="clienteId"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowNewConsultaBtn"
          small
          class="mr-2"
          @click="requisitarExames(item.petId, item.clienteId)"
        >
          mdi-clipboard-text-clock
        </v-icon>

        <v-icon small class="mr-2" @click="verArquivos(item)">
          fas fa-folder
        </v-icon>

        <v-icon
          v-if="allowNewConsultaBtn"
          small
          class="mr-2"
          @click="requisitarConsulta(item.petId, item.clienteId)"
        >
          mdi-application-import
        </v-icon>

        <v-icon
          v-if="allowVisualizarBtn"
          small
          class="mr-2"
          @click="editItem(item, true)"
        >
          mdi mdi-eye
        </v-icon>
        <v-icon
          v-if="allowEditarBtn"
          small
          class="mr-2"
          @click="editItem(item, false)"
        >
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="showDialogAgendarPet(item)">
          fas fa-file-medical
        </v-icon>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import modalCadastro from "@/components/pets/modal_cadastro";
import modalEdicao from "@/components/pets/modal_edicao";
import PetsService from "@/services/pets_service";
import Arquivo from "@/components/prontuarios/arquivos.vue";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import ModalPetAgendamento from "@/components/pets/modal_pet_agendamento";
import RequisitarConsulta from "@/components/recepcao/infoChamada/buttons/marcar-consulta/requisitarConsulta-semcf.vue";
import RequisitarExamesPets from "@/components/requisitar_exames/requisitar_exame_pet";

export default {
  mixins: [Mixin],
  components: {
    modalCadastro,
    modalEdicao,
    ModalPetAgendamento,
    RequisitarConsulta,
    RequisitarExamesPets,
    Arquivo,
  },
  props: { petId: String, tutor: Object },
  data: () => ({
    clienteId: "",
    petArquivo: {},
    dialogArquivo: false,
    dialogAgendarPet: false,
    dialogRequisitarConsulta: false,
    dialogRequisitarExames: false,
    petAgendamento: {},
    dialogCadastrar: false,
    dialogEdicao: false,
    dialogDelete: false,
    dialog: false,
    dialogObs: false,
    editedPetId: {},
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    dialogCadastro: false,
    pet: {},
    pets: [],
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "petId",
      },
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      // { text: "Cor", value: "cor", sortable: false },
      { text: "Cliente", value: "clienteNome", sortable: false },
      { text: "CPF do cliente", value: "clienteCpf", sortable: false },
      { text: "Sexo", value: "sexo", sortable: false },
      // {
      //   text: "RGA Pedigre",
      //   value: "rgaPedigre",
      //   sortable: false,
      // },
      // {
      //   text: "Especie",
      //   value: "nomeEspecie",
      //   sortable: false,
      // },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    loadingDelete: false,
    services: {
      petService: PetsService.build(),
    },
    visualizar: false,
  }),

  watch: {
    dialog(val) {
      val || this.closeDialogCadastrar();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.atualizarPets();
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.atualizarPets();
      }
    },
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarPets();
      },
      deep: true,
    },
  },

  mounted() {
    this.atualizarPets();
    console.log(this.tutor);
  },
  computed: {
    allowCadastrarBtn() {
      return 1;
    },
    allowEditarBtn() {
      return 1;
    },
    allowVisualizarBtn() {
      return 1;
    },
    allowNewConsultaBtn() {
      return 1;
    },
    allowDeletarBtn() {
      return 1;
    },
    titulo() {
      return JSON.parse(sessionStorage.vuex).perfil.id === 4
        ? "Meus pacientes"
        : "Pacientes";
    },
  },
  methods: {
    verArquivos(item) {
      this.petArquivo = { id_paciente: item.pacienteId, id_pet: item.petId };
      this.dialogArquivo = true;
    },
    requisitarConsulta(idPet, idTutor) {
      this.petId = idPet;
      this.clienteId = idTutor;
      this.dialogRequisitarConsulta = true;
    },
    requisitarExames(idPet, idTutor) {
      this.petId = idPet;
      this.clienteId = idTutor;
      this.dialogRequisitarExames = true;
    },
    atualizarPetPosCadastro() {
      this.atualizarPets();
      this.closeDialogCadastrar();
    },
    atualizarPetPosEdicao() {
      this.dialogEdicao = false;
      this.atualizarPets();
    },
    getPaginationParams() {
      return {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.order,
        desc: this.options.desc,
        search: this.search,
      };
    },
    async atualizarPets() {
      this.loading = true;
      await this.services.petService
        .getPetByTutorId(this.petId)
        .then(async (response) => {
          this.pets = response.map((pet) => this.mapPet(pet));
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mapPet(pet) {
      return {
        petId: pet.id_pet,
        nome: pet.nome_pet ?? "",
        clienteNome: pet.nome,
        clienteCpf: pet.cpf,
        cor: pet.cor,
        sexo: pet.sexo,
        rgaPedigre: pet.rga_pedigree,
        nomeEspecie: pet.nome_especie,
        clienteId: pet.id_cliente,
        pacienteId: pet.id_paciente,
      };
    },
    editItem(item, bool) {
      this.visualizar = bool;
      this.editedPetId = parseInt(item.petId) || 0;
      this.dialogEdicao = true;
    },

    deleteItem({ petId }) {
      this.petIdToDelete = petId;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.loadingDelete = true;
      this.services.petService
        .deletarpet(this.petIdToDelete)
        .then(async ({ status }) => {
          this.$_ACTIONS_showSnackbarMessage({
            message:
              status === 200
                ? "O pet foi deletado com sucesso."
                : this.$global.messages.internalServerError,
            color: status === 200 ? "sucess" : "error",
          });
          status === 200 && (await this.atualizarPerfis());
        })
        .catch((e) => console.error(e))
        .finally(() => {
          this.loadingDelete = false;
          this.closeDelete();
        });
    },

    closeDialogCadastrar() {
      this.dialogCadastrar = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDialogEdited() {
      this.dialogEdicao = false;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDialogAgendarPet() {
      this.dialogAgendarPet = false;
    },

    showDialogAgendarPet(item) {
      this.dialogAgendarPet = true;
      this.petAgendamento = item;
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: inline-flex;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px !important;
  padding: 10px !important;
  border-radius: 50px;
}
</style>
